<template>
  <div class="page_my_order right_connent">
    <div class="title">我的订单</div>
    <tab_comm :tab_list="tab_list" @tab_index="tab_index" />

    <ul class="connent_wrap infinite-list" v-infinite-scroll="load" style="overflow: auto" infinite-scroll-distance="30">
      <li class="item_order infinite-list-item" v-for="(item, index) in my_order_list" :key="index">
        <div class="time">
          {{ item.create_time }}
          <div class="copy" v-clipboard:copy="item.order_no" v-clipboard:success="onCopy" v-clipboard:error="onError">
            订单号：{{ item.order_no
            }}<input class="input" type="text" v-model="item.order_no" />
            <img src="@/assets/mine/copy.png" alt="" />
          </div>
        </div>
        <div class="label_wrap">
          <div class="label">{{item.good_type | filterGoodType}}</div>
        </div>
        <div class="name">{{ item.good_name }}</div>
        <div class="end_time">过期时间：{{ item.expire_time }}</div>
        <div class="bottom" v-if="item.notify_status == 0">
          <div class="price">应付：￥{{item.order_price}}</div>
          <div class="gry">
            待付款
            <div class="btn pay" @click="go_pay(item)">立即付款</div>
          </div>
        </div>
        <div class="bottom" v-if="item.notify_status == 1">
          <div class="price">实付：￥{{ item.actual_price?item.actual_price:'0.00' }}</div>
          <div class="btn success" @click="go_my_order_detail(item.id)">
            支付完成
          </div>
        </div>
        <div class="bottom" v-if="item.notify_status == 2">
          <div class="price">应付：￥{{ item.order_price }}</div>
          <div class="btn">取消支付</div>
        </div>
        <div class="bottom" v-if="item.notify_status == 3">
          <div class="price">应付：￥{{ item.order_price }}</div>
          <div class="btn">已退费</div>
        </div>
      </li>
    </ul>
    <div class="page_wrap">
      <el-pagination background :pager-count="5" :page-size="limit" layout="total, prev, pager, next, jumper" :total="total" @prev-click="prev_click" @next-click="next_click"
        @current-change="current_change" :hide-on-single-page="true" :current-page="page">
      </el-pagination>
    </div>
    <div class="no_data" style="
        text-align: center;
        color: #999999;
        margin-top: 130px;
      " v-show="total == 0">
      <img style="width: 145px" src="@/assets/common/nodata.png" alt="" />
      <p style="margin-top: 10px">暂无数据</p>
    </div>
  </div>
</template>

<script>
import tab_comm from "@/components/tab_comm.vue";
import api from "@/config/api.js";
let Base64 = require("js-base64").Base64;
export default {
  name: "my_order",
  data() {
    return {
      tab_list: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "待付款",
        },
        {
          id: 2,
          name: "已完成",
        },
      ],
      message: "326546549802567",
      page: 1,
      limit: 3,
      type: "",
      my_order_list: [],
      total: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // ...mapActions(["setUserAndState"]),
    tab_index(tab_index) {
      // console.log(tab_index, "111");
      if (tab_index == 0) {
        this.type = "";
      } else if (tab_index == 1) {
        this.type = 0;
      } else if (tab_index == 2) {
        this.type = 1;
      }
      this.page = 1;
      console.log(this.type, tab_index, this.page);
      this.getData();
    },
    load() {
      
    },
    prev_click(e) {
      this.page = e;
      this.getData();
    },
    next_click(e) {
      this.page = e;
      this.getData();
    },
    current_change(e) {
      this.page = e;
      this.getData();
    },

    go_my_order_detail(id) {
      let { href } = this.$router.resolve(`/mine/my_order_detail/${id}?item=2`);
      window.open(href, "_blank");
    },
    onCopy: function (e) {
      this.$notify({
        title: `订单编号：${e.text}`,
        message: "复制成功",
        type: "success",
      });
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },
    // async go_orderinfo() {
    //   let islogin = await this.islogin();
    //   if (islogin) {
    //     var base64_checked_str = Base64.encode(
    //       JSON.stringify([
    //         {
    //           good_id: this.course_id,
    //           num: 1,
    //         },
    //       ])
    //     );
    //     let { href } = this.$router.resolve(
    //       `/order_info/${base64_checked_str}`
    //     );
    //     window.open(href, "_blank");
    //   } else {
    //     this.$router.push(`/login?re_url=${this.re_url}`);
    //   }
    // },
    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },
    async go_pay(item) {
      try {
        let res = await api.again_pay({
          id: item.id,
        });
        if (res.code == 0) {
          var param = Base64.encode(
            JSON.stringify({
              oid: res.result.id,
              price: res.result.order_price,
            })
          );
          this.$router.push(`/pay/${param}`);
        } else {
          this.$message(res.message);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getData() {
      try {
        const res = await api.my_order({
          page: this.page,
          limit: this.limit,
          type: this.type,
        });
        if (res.code == 0) {
          let data = res.result;
          this.my_order_list = data.data;
          this.total = data.count;
          if (data.length == 0) {
          } else {
          }
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    tab_comm,
  },
  filters:{
    filterGoodType(type){
      if(type==1){
        return '课程'
      } else if(type==2){
        return '图书'
      } else if(type==3){
        return '模考'
      } else if(type==4){
        return '历年真题'
      } else {
        return '其它'
      }
    }
  }
};
</script>
<style scoped>
</style>

<style lang="less" scoped>
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.page_wrap {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
.gry {
  font-size: 14px;
  
  font-weight: 400;
  color: #999999;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.page_my_order {
  position: relative;
  background-color: #fff;
  text-align: left;
  min-height: 753px;
  .title {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 35px;
  }
  .connent_wrap {
    margin-top: 30px;
    .item_order {
      border-top: 1px solid #e9e9e9;
      padding: 30px 0;
      .copy {
        cursor: pointer;
      }
      .time {
        font-size: 14px;
        
        font-weight: 400;
        color: #999999;
        display: flex;
        justify-content: space-between;
        input {
          opacity: 0;
          width: 0;
        }
        img {
          width: 12px;
          height: 14px;
        }
      }
      .label_wrap {
        margin: 5px 0;
        display: flex;
        align-items: center;
        margin-right: 10px;
        .label {
          width: 57px;
          height: 24px;
          background: #eff3fd;
          border-radius: 4px;
          font-size: 14px;
          
          font-weight: 400;
          color: #479dff;
          line-height: 24px;
          text-align: center;
        }
      }
      .name {
        font-size: 18px;
        
        font-weight: 500;
        color: #151515;
      }
      .end_time {
        font-size: 14px;
        
        font-weight: 400;
        color: #999999;
        margin-top: 10px;
      }
      .bottom {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          font-size: 16px;
          
          font-weight: 500;
          color: #1f1f1f;
        }
        .btn {
          width: 160px;
          height: 40px;
          background: #f7f6f9;
          border-radius: 20px;
          font-size: 18px;
          
          font-weight: 500;
          color: #999999;
          line-height: 28px;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
        }
        .btn.success {
          color: #479dff;
          background: #eff3fd;
        }
        .btn.pay {
          background: #f96158;
          color: #ffffff;
          margin-left: 13px;
        }
      }
    }
  }
}
</style>